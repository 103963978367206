.hit-icon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hit-icon > * {
  line-height: 1;
}
.hit-icon svg {
  display: inline-block;
}
.hit-icon::before {
  display: none;
}
.hit-icon[tabindex] {
  cursor: pointer;
}
.hit-icon .hit-icon-spin::before,
.hit-icon .hit-icon-spin {
  display: inline-block;
  -webkit-animation: hit-icon-loading-circle 1s infinite linear;
  animation: hit-icon-loading-circle 1s infinite linear;
}
@-webkit-keyframes hit-icon-loading-circle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes hit-icon-loading-circle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
